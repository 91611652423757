<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Terminal Uploads</h1>

      <div class="mt-14">
        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2 mx-auto">
          <!-- Column Content -->
          <a
            href="/files/terminalUploadSample.xlsx"
            class="relative rounded mx-2 text-xs px-5 py-2.5 overflow-hidden group bg-green-500 relative hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300"
          >
            <span
              class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"
            ></span>
            <span class="relative">Download Sample</span>
          </a>
          <a
            href="/files/DBUPLOAD16_test_ga_traction.xlsx"
            class="relative rounded mx-2 text-xs px-5 py-2.5 overflow-hidden group bg-green-500 relative hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300"
          >
            <span
              class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"
            ></span>
            <span class="relative">Download GA Sample</span>
          </a>
          <div
            class="w-full border-2 drop-shadow-sm border-gray-100 flex flex-col bg-white rounded-lg overflow-hidden cursor-pointer"
          >
            <div class="border-t border-gray-200 p-5">
              <p class="font-medium mt-2 text-sm">Upload Terminals</p>
              <div v-if="firstPage">
                <div class="drop-shadow-2xl rounded-md p-3 mt-5">
                  <p class="font-medium text-xs">
                    Select Terminal Type <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <select
                      type="text"
                      v-model="terminalType"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                    >
                      <option value="" disabled>Select Terminal Type</option>
                      <option value="Traction terminals">
                        Traction terminals
                      </option>
                      <option value="GA terminals">GA terminals</option>
                    </select>
                  </p>
                  <p
                    v-if="errors.terminalType && terminalType === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.terminalType }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-3">
                  <p class="font-medium text-xs">
                    Select File <span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      ref="inputFile"
                      accept=".xlsx, .xls, .csv"
                      @change="onFileChange"
                      type="file"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                    />
                  </p>
                  <p
                    v-if="errors.inputFile && inputFile === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.inputFile }}
                  </p>
                </div>
                <div class="p-3">
                  <button
                    @click="handleCancel"
                    class="inline uppercase shadow bg-yellow-600 mt-5 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    @click="handleUpload"
                    class="inline uppercase shadow ml-5 bg-blue-600 mt-5 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import readXlsxFile from "read-excel-file";
export default {
  data() {
    return {
      moment,
      promptDiv: false,
      isLoading: false,
      firstPage: true,
      terminalType: "",
      errors: {
        terminalType: "",
        inputFile: null,
      },
      files: [],
    };
  },
  computed: {},
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    async handleUpload() {
      // console.log(this.files);
      if (this.files.length > 0 && this.terminalType !== "") {
        this.isLoading = true;
        if (this.terminalType === "GA terminals") {
          let upload = { Sheet1: this.files[0] };
          try {
            let res = await this.$store.dispatch(
              "UPLOAD_GA_TERMINALS_VIA_XLS",
              upload
            );
            this.isLoading = false;
            console.log(res.status);

            if (res.status) {
              // window.open(res.data, "_blank");
              alert(res.message);
              this.fetchRecords();
            } else {
              console.log(res);
              alert(
                res.error ||
                  res.message ||
                  "Error: Please check network or contact admin."
              );
            }
          } catch (error) {}
        } else {
          let upload = this.files[0];
          try {
            let res = await this.$store.dispatch(
              "UPLOAD_TERMINALS_VIA_XLS",
              upload
            );
            this.isLoading = false;

            if (res.status) {
              // window.open(res.data, "_blank");
              alert(res.message);
              this.fetchRecords();
            } else {
              alert(
                res.error ||
                  res.message ||
                  res.error ||
                  "Error: Please check network or contact admin."
              );
            }
          } catch (error) {
            console.log(error);
          }
        }
        this.terminalType === "";
      }
    },
    handleCancel() {},
    onFileChange(event) {
      this.files = [];
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      const schema = {
        MERCHANTID: {
          prop: "MERCHANTID",
          type: String,
        },
        MERCHANTNAME: {
          prop: "MERCHANTNAME",
          type: String,
        },
        MERCHANTACCOUNTNAME: {
          prop: "MERCHANTACCOUNTNAME",
          type: String,
        },
        CONTACTTITLE: {
          prop: "CONTACTTITLE",
          type: String,
        },
        CONTACTNAME: {
          prop: "CONTACTNAME",
          type: String,
        },
        MOBILEPHONE: {
          prop: "MOBILEPHONE",
          type: String,
        },
        EMAIL: {
          prop: "EMAIL",
          type: String,
        },
        EMAILALERTS: {
          prop: "EMAILALERTS",
          type: String,
        },
        PHYSICALADDR: {
          prop: "PHYSICALADDR",
          type: String,
        },
        TERMINALMODELCODE: {
          prop: "TERMINALMODELCODE",
          type: String,
        },
        TERMINALID: {
          prop: "TERMINALID",
          type: String,
        },
        BANKCODE: {
          prop: "BANKCODE",
          type: String,
        },
        BANKACCNO: {
          prop: "BANKACCNO",
          type: String,
        },
        BANKACCTYPE: {
          prop: "BANKACCTYPE",
          type: String,
        },
        SLIPHEADER: {
          prop: "SLIPHEADER",
          type: String,
        },
        SLIPFOOTER: {
          prop: "SLIPFOOTER",
          type: String,
        },
        BUSINESSOCCUPATIONCODE: {
          prop: "BUSINESSOCCUPATIONCODE",
          type: String,
        },
        MERCHANTCATEGORYCODE: {
          prop: "MERCHANTCATEGORYCODE",
          type: String,
        },
        STATECODE: {
          prop: "STATECODE",
          type: String,
        },
        VISAACQUIRERIDNUMBER: {
          prop: "VISAACQUIRERIDNUMBER",
          type: String,
        },
        VERVEACQUIRERIDNUMBER: {
          prop: "VERVEACQUIRERIDNUMBER",
          type: String,
        },
        MASTERCARDACQUIRERIDNUMBER: {
          prop: "MASTERCARDACQUIRERIDNUMBER",
          type: String,
        },
        TERMINALOWNERCODE: {
          prop: "TERMINALOWNERCODE",
          type: String,
        },
        LGALCDA: {
          prop: "LGALCDA",
          type: String,
        },
        POSTCODE: {
          prop: "POSTCODE",
          type: String,
        },
        MERCHANTURL: {
          prop: "MERCHANTURL",
          type: String,
        },
        PTSP: {
          prop: "PTSP",
          type: String,
        },
        DEVICETYPE: {
          prop: "DEVICETYPE",
          type: String,
        },
        DEVICENAME: {
          prop: "DEVICENAME",
          type: String,
        },
        DEVICESN: {
          prop: "DEVICESN",
          type: String,
        },
        APPNAME: {
          prop: "APPNAME",
          type: String,
        },
        APPVERSION: {
          prop: "APPVERSION",
          type: String,
        },
        NETWORKTYPE: {
          prop: "NETWORKTYPE",
          type: String,
        },
        COSTPRICEBAND: {
          prop: "COSTPRICEBAND",
          type: Number,
        },
      };
      readXlsxFile(xlsxfile, { schema }).then((rows) => {
        this.files.push(rows.rows);
      });
      console.log(this.files);
    },
  },
};
</script>
